import type { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'

import Layout from '../components/layout'

const Page: NextPage = () => {
  return (
    <Layout
      title="Page Not Found"
      slug="404"
      meta={undefined}
      header={undefined}
      footer={undefined}
    >
      <div className="flex h-full w-full bg-sp-light-purple">
        <div className="container m-auto flex flex-col items-center justify-center md:flex-row">
          <div className="px-12 md:order-2 md:col-span-4 md:w-1/3 md:px-0">
            <Image
              src="/images/404.png"
              alt="Page not found"
              width={827}
              height={708}
              priority
            />
          </div>
          <div className="px-12 text-center md:col-span-4 md:col-start-3 md:w-1/3 md:px-0 md:text-left">
            <h1 className="text-4xl">Whoops!</h1>
            <p className="my-4">
              Looks like this page can&#39;t be found. It may have moved or been
              renamed.
            </p>
            <Link href="/">
              <a className="font-base h-10 w-full rounded bg-sp-primary py-2 px-10 text-white">
                Return to home
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
